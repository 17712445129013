
:root {
    color-scheme: light dark;
}


@media(prefers-color-scheme: dark) {

    .card-header span {
        color: white !important;
    }

    .transfer-list {
        background-color: #424242 !important;
    }

    .arrow-button:disabled {
        color: rgba(255, 255, 255, 0.48) !important;
        border-color: rgba(255, 255, 255, 0.48) !important;
    }

    .arrow-button {
        color: rgba(255, 255, 255, 0.8) !important;
        border-color: rgba(255, 255, 255, 0.8) !important;
        border-width: 1px !important;
    }

    .arrow-button:hover {
        color: rgba(255, 255, 255, 1) !important;
        border-color: rgba(255, 255, 255, 1) !important;
    }

    .transfer-list span {
        color: white !important;
    }

    table {
        background-color: #424242;
    }

    .MuiPaper-root {
        background-color: rgb(45, 45, 45) !important;
        color: white !important;
    }

    .MuiDialog-container {
        background-color: rgba(105, 105, 105, 0.1);
    }

    .MuiPaper-root p {
        color: #dcdcdc !important
    }

    td {
        border-bottom-color: rgba(255, 255, 255, 0.12) !important;
    }
    .table-body, .table-row {
        background-color: #424242;
    }

    .p-text {
        color: white
    }

    .arrow-button {
        color: rgba(255, 255, 255, 0.7);
    }

    .table-row {
        border-bottom: solid 1px #424242 !important;
    }

    .picker {
        color: white !important;
    }

    .picker .MuiSvgIcon-root {
        color: white !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border-color: #ffffff !important;
        border-width: 1px;
    }

}
